<template>
  <bg-modal
    :value="isOpen"
    :title="title"
    :description="description"
    desktop-size="sm"
    :closable="false"
    :close-on-click-backdrop="false"
  >
    <template #footer>
      <div class="flex justify-end">
        <bg-button class="mr-16" :disabled="isLoading" @click="closeModal"
          >Tidak</bg-button
        >
        <bg-button
          variant="primary"
          :loading="isLoading"
          @click="handleActionAgreement"
          >Ya</bg-button
        >
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgModal, BgButton } from 'bangul-vue';
import disbursementApi from '@admin_endpoints/disbursement-approval';

export default {
  name: 'DisbursementApprovalModal',

  components: {
    BgModal,
    BgButton,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    approvalId: {
      type: [String, Number],
      default: null,
    },
    status: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    title() {
      switch (this.status) {
        case 'unapproved':
          return 'Yakin konfirmasi pendapatan pemilik?';

        case 'approved':
          return 'Yakin batalkan konfirmasi pendapatan pemilik?';

        default:
          return '';
      }
    },
    description() {
      switch (this.status) {
        case 'unapproved':
          return 'Data yang sudah dikonfirmasi akan diteruskan ke tim finance untuk ditransfer.';

        case 'approved':
          return 'Data akan dihapus dari daftar transfer di tim finance.';

        default:
          return '';
      }
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
      this.$emit('update:approvalId', null);
      this.$emit('update:status', '');
    },

    async handleActionAgreement() {
      if (this.approvalId && this.status) {
        this.isLoading = true;

        try {
          if (this.status === 'unapproved') {
            await disbursementApi.approveContract(this.approvalId);
            this.$toast.show('Data berhasil dikonfirmasi');
          } else if (this.status === 'approved') {
            await disbursementApi.unApproveContract(this.approvalId);
            this.$toast.show('Konfirmasi berhasil dibatalkan');
          }

          this.$emit('action-success');
        } catch (error) {
          const errorMessage =
            error?.response?.data.issue?.message ||
            'Terjadi kesalahan silahkan coba lagi.';

          this.$toast.show(errorMessage);
        } finally {
          this.isLoading = false;
          this.closeModal();
        }
      }
    },
  },
};
</script>
